﻿img {
	max-width: 100%;
	height: auto;
	transition: $transition-base;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	// WordPress
	&.alignleft {
		float: left;
		margin-right: 1rem;
	}

	&.alignright {
		float: right;
		margin-left: 1rem;
	}

	&.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

a {
	@include hover-focus {
		img {
			opacity: .8;
		}
	}
}