.arrow{
    position: relative;
    display: block;
    padding: 0 0 0 16px;
    color: #000;
    vertical-align: middle;
    text-decoration: none;
    font-size: 15px;
}
.arrow::before,
.arrow::after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
}
.list-title-icon::before{
    width: 12px;
    height: 12px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #4B4B4B;
}
.list-title-icon::after{
    left: 5px;
    box-sizing: border-box;
    width: 3px;
    height: 3px;
    border: 3px solid transparent;
    border-left: 3px solid #fff;
}
.list-content-icon::before{
    left: 4px;
    box-sizing: border-box;
    width: 4px;
    height: 4px;
    border: 4px solid transparent;
    border-left: 4px solid #4B4B4B;
}

.footer-links-wrap{
	@include media-breakpoint-down(sm){
		display: none;
	}
	.footer-links{
		font-size: rem-calc(12);
		.col-4, .col-5{
			padding: 20px;
		}
		.footer-links_left{
			background-color: #E5F4FC;
			p{
				font-size: rem-calc(12);
				color: #4B4B4B;
				a{
					color: #4B4B4B;
					text-decoration: underline;
				}
			}
			.links-title{
				font-size: rem-calc(14);
				font-weight: bold;
			}
		}
		ul{
			padding: 0;
			.list-title{
				margin-bottom: 10px;
			}
			li{
				margin-bottom: 10px;
				text-decoration: none;
				a{
					color: #4B4B4B;
					font-size: rem-calc(12);
				}
			}
		}
	}
}
footer{
	background-color: #000;
	color: #fff;
	padding-bottom: 10px;
	.footer-nav{
		display: flex;
		justify-content: center;
		list-style: none;
		padding: 35px 0 20px;
		@include media-breakpoint-down(sm){
			display: block;
		}
		li{
			margin: 0 10px;
			@include media-breakpoint-down(sm){
				display: inline-block;
				margin: 10px;
			}
			a{
				color: #fff;
			}
		}
	}
	.copyright{
		font-family: Georgia;
	}
}