﻿//
// Home
//

body.home {

	//
	// Header
	//
	.header {
		margin-bottom: 0;
	}


	//
	// Hero
	//
	.hero {
		img {
			width: 100%;
			height: auto;
		}

		// Slick arrows
		.slick-arrow {
			@include image-replacement();
			cursor: pointer;
			outline: 0;
			display: block;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: rgba(#000000, 0.5);
			position: absolute;
			z-index: 2;
			top: 50%;
			margin-top: -30px;
			border: none;
			padding: 0;

			&:after {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 15px;
				height: 15px;
				border-top: 3px solid #FFF;
				border-left: 3px solid #FFF;
			}

			&.slick-prev {
				left: 10%;
				margin-left: -15px;

				&:after {
					transform: translate(-50%, -50%) rotate(-45deg);
					margin-left: 3px;
				}
			}

			&.slick-next {
				right: 10%;
				margin-right: -15px;

				&:after {
					transform: translate(-50%, -50%) rotate(135deg);
					margin-left: -3px;
				}
			}
		}

		// Slick dots
		.slick-dots {
			display: flex;
			justify-content: center;
			align-items: center;
			list-style: none;
			margin: 20px 0;
			padding: 0;

			> li {
				margin: 0 4px;
				display: flex;
				align-items: center;

				button {
					@include image-replacement();
					border-radius: 50%;
					background-color: #999999;
					width: rem-calc(8);
					height: rem-calc(8);
					cursor: pointer;
					border: none;
					padding: 0;
				}

				&.slick-active {
					button {
						background-color: $primary;
					}
				}
			}
		}
	}

	.main-visual_wrap{
		.main-visual_wrap_content{
			position: relative;
			.main-visual_content_image{
				display: block;
				width: 100%;
				@include media-breakpoint-down(sm){
					display: none;
				}
			}
			.main-visual_content_image_sp{
				display: none;
				width: 100%;
				@include media-breakpoint-down(sm){
					display: block;
				}
			}
			.main-visual_content_text{
				position: absolute;
				top: 30%;
				left: 3%;
				@include media-breakpoint-down(sm){
					width: 80%;
					top: 20%;
					left: 3%;
				}
			}
			.news-wrap{
				position: absolute;
				width: 365px;
				bottom: 8%;
				right: 5%;
				@include media-breakpoint-down(sm){
					position: relative;
					width: 80%;
					margin: 20px auto;
					bottom: 0%;
					right: 0%;
				}
				h2{
					position: relative;
					background-color: #B8E4FA;
					font-size: rem-calc(18);
					margin-bottom: 0;
					padding: 10px 35px;
					z-index: 10;
					&::before{
						content: url(../img/home/img_top_news-icon.png);
						position: absolute;
						top: -10px;
						left: -25px;
					}
				}
				.news-wrap_content{
					background-color: #FFFBE0;
					color: #4B4B4B;
					font-size: rem-calc(13);
					padding: 15px 35px;
					@include media-breakpoint-down(sm){
						padding: 15px;
					}
				}
				.read-more{
					text-align: right;
					a{
						font-size: rem-calc(12);
						color: #4B4B4B;
						text-decoration: underline;
					}
					.arrow{
						display: inline-block;
					}
				}
			}
		}
	}
	.new-contents{
		background-color: #F0F0F0;
		color: #4B4B4B;
		padding: 35px 0;
		.new-content_title{
			position: relative;
			h2{
				text-align: center;
				font-size: rem-calc(18);
				margin-bottom: 20px;
			}
			p{
				position: absolute;
				top: 0;
				right: 0;
				margin-bottom: 0;
				a{
					color: #4B4B4B;
					text-decoration: underline;
				}
			}
		}
		.new-content_list{
			font-size: rem-calc(13);
			dl{
				display: flex;
				margin-bottom: 0;
				border-top: 1px solid #fff;
				border-bottom: 1px solid #fff;
				padding: 6px 0;
				@include media-breakpoint-down(sm){
					display: block;
				}
				dt{
					width: 10%;
					font-weight: normal;
				}
				dd{
					margin-bottom: 0;
					a{
						color: #4B4B4B;
						margin-left: 20px;
					}
				}
			}
		}
	}
	.post{
		.col-12{
			padding: 0;
		}
		.drugs{}
		.information{
			background-color: #EDF9FF;
		}
		.video{
			background-color: #FFFBE0;
		}
		.cm{}
		.drugs, .information, .video, .cm{
			padding: 20px;
		}
		.drugs_title, .information_title, .video_title, .cm_title{
			position: relative;
			h2{
				font-size: rem-calc(15);
			}
			p{
				position: absolute;
		    top: 0;
		    right: 0;
		    margin-bottom: 0;
		    a{
					color: #4B4B4B;
					text-decoration: underline;
				}
			}
		}
		.drugs_content, .information_content, .video_content, .cm_content{
			margin: 10px;
			p{
				word-wrap: break-word;
			}
		}
	}

	.pages{
		.container{
			padding: 0;
		}
		.pages-wrap{
			display: flex;
			@include media-breakpoint-down(sm){
				display: block;
			}
			.page-content{
				position: relative;
				text-align: center;
				width: 100%;
				padding: 120px 0;
				color: #fff;
				margin: 0 .5px;
				@include media-breakpoint-down(sm){
					width: 49%;
					margin: 0;
					display: inline-block;
				}
				p{
					position: absolute;
					transform: translate(-50%, -50%);
					top: 30%;
   				left: 50%;
   				width: 100%;
				}
				img{
					position: absolute;
					transform: translate(-50%, -50%);
					bottom: 10%;
   				left: 50%;
				}
				&::before{
					content: "";
					position: absolute;
					border-top: 20px solid #fff;
				  border-right: 20px solid transparent;
				  border-bottom: 20px solid transparent;
				  border-left: 20px solid #fff;
				  top: 5px;
				  left: 5px;
				}
			}
			.course{
				background-color: #69ADD7;
			}
			.school{
				background-color: #41BCC4;
			}
			.tv{
				background-color: #93B74B;
			}
			.pharmacy{
				background-color: #DFB573;
			}
			.search{
				background-color: #DF9273;
				@include media-breakpoint-down(sm){
					width: 100%;
				}
			}
		}
	}

	.links{
		padding: 30px 80px 60px;
		background-color: #F0F0F0;
		@include media-breakpoint-down(sm){
			padding: 30px 0px 60px;
		}
		.links-wrap{
			h2{
				font-size: rem-calc(18);
				text-align: center;
				margin-bottom: 30px;
			}
			ul{
				padding: 0;
				li{
					list-style: none;
					margin-bottom: 30px;
					a{
						text-decoration: underline;
						color: #4B4B4B;
					}
				}
			}
		}
	}
	
}
