﻿//
// Page
//


body.page {
	article{

	}

	.page-header-wrap{
		@include media-breakpoint-down(sm){
			padding: 0;
		}
	}

	.page-header{
		padding-top: 30px;
		padding-bottom: 30px;
		background-repeat: no-repeat;
		background-size: cover;
    background-image: url(../img/page/img_header-back.jpg);
    p{
    	color: #fff;
    	font-size: rem-calc(18);
    	padding-left: 50px;
    	margin-bottom: 0;
    }
	}

	.page-title-wrap{
		margin-left: 3rem;
		@include media-breakpoint-down(sm){
			margin-left: 0;
		}
	}

	h1{
		padding: 12px 0 12px 50px;
		background-color: #B8E4FA;
		font-size: rem-calc(18);
		@include media-breakpoint-down(sm){
			padding: 12px 0 12px 5px;
		}
	}

	h2{
		font-size: rem-calc(16);
		border-left: 5px solid $primary;
		border-bottom: 1px solid $primary;
		padding: 5px;
	}

	h3{
		font-size: rem-calc(16);
		position: relative;
		padding-left: 14px;
		&::before{
			background: $primary;
      content: "";
      height: 10px;
      width: 10px;
      left: 0;
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
		}
	}

	h4{
		font-size: rem-calc(14);
		position: relative;
		padding-left: 14px;
		&::before{
			background: #000;
      content: "";
      height: 10px;
      width: 10px;
      left: 0;
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
		}
	}

	.breadcrumb-container{

	}

	.breadcrumb{
		border-radius: 0;
		max-width: 1200px;
    margin: auto;
    background-color: #fff;
    .breadcrumb-item{
    	position: relative;
	    display: block;
	    padding: 0 0 0 16px;
	    color: #000;
	    vertical-align: middle;
	    text-decoration: none;
	    font-size: 15px;
	    &::before{
	    	position: absolute;
		    top: 0;
		    bottom: 0;
		    left: 0;
		    margin: auto;
		    content: "";
		    vertical-align: middle;
		    padding-right: 0;
		    left: 3px;
		    width: 8px;
		    height: 8px;
		    border-top: 1px solid #4B4B4B;
		    border-right: 1px solid #4B4B4B;
		    -webkit-transform: rotate(45deg);
		    transform: rotate(45deg);
	    }
	    &:first-child:before{
	    	display: none;
	    }
	    a{
	    	color: #4B4B4B;
	    	text-decoration: underline;
	    }
    }
	}

	.row>* {
    margin-bottom: 25px;
	}

	a:link {color:$primary;}
	a:visited {color:$primary;}
	a:hover {color:#00AA22;}

	.box-important{
		border: 4px solid #0D0B78;
    padding: 5px 15px;
	}

	.box-notimportant{
    padding: 5px 15px;
	}

	.quote-text{
		border-left: 6px solid $primary;
		p{
			margin-left: 12px;
			span{
				display: block;
				text-align: right;
			}
		}
	}

	.list-icon{
		list-style: none;
		li{
			display: block;
		  padding: .5em 1em .5em 20px;
		  position: relative;
			&::before{
		    position: absolute;
		    top: 1.25em;
		    margin-top: -4px;
		    left: 2px;
		    content: "";
		    display: block;
		    width: 8px;
		    height: 8px;
		    background-color: $primary;
		    border-radius: 4px;
			}
		}
	}

	.no-list{
		list-style: none;
	}

	.type--small{
		li::before{
			margin-top: -2.5px;
	    left: 2px;
	    width: 5px;
	    height: 5px;
	    border-radius: 2.5px;
		}
	}

	.type--square{
		li::before{
	    margin-top: -5px;
	    left: 1px;
	    width: 10px;
	    height: 10px;
	    border-radius: 0;			
		}
	}

	.type--border{
		li:before{
	    margin-top: -2px;
	    left: 1px;
	    width: 10px;
	    height: 4px;
	    border-radius: 0;
		}
	}

table{
	width: 100%;
	border: none;
	border-collapse: collapse;
	border-spacing: 1;
	margin-bottom: 2em;
	border: 1px solid #9f9f9f; 
	caption{
		text-align: center;
		margin: 0;
		&.left{ text-align: left; }
		&.right{ text-align: right; }
	}
	tr{
		th, td{
			padding:0.5em 10px;
			border: 1px solid #9F9F9F;
			text-align: center;
		}
	}
	thead{
		tr{
			th{
				background-color: #B8E4FA;
				color: #333;
				text-align: center;
			}
		}
	}
	tbody{
		tr{
			th{
				background-color: #EBEBEB;
			}
		}
	}
	&.style-oddeven{
		tbody{
			tr{
				&:nth-child(odd){
					background-color: #EFEFEF;
				}
			}
		}
	}
	&.style-simple{
		tr{
			th, td{
				border: none;
				border-bottom: 1px solid #9F9F9F;
			}
		}
		thead{
			tr{
				th{
					background-color: transparent;
					color: $gray-800;
				}
				td, th{
					border-bottom: 3px solid #CCCCCC;
				}
			}
		}
		&.color-blue{
			thead{
				tr{
					td, th{
						border-bottom: 3px solid $primary;
					}
				}
			}
		}
	}
}

.wrap-table {
	@include media-breakpoint-down(sm) {
		width: auto;
		overflow: auto;
		background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)) 0 0/20px 100%, linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2)) right/20px 100%;
		background-repeat: no-repeat;
		background-attachment: scroll;
		margin-bottom: 2em;
		table {
			min-width: 600px;
			margin: 0px;
			background: linear-gradient(to left, hsla(0,0%,100%,0), white 15px) 0 0 / 50px 100%, linear-gradient(to right, hsla(0,0%,100%,0), white 15px) right / 50px 100%;
			background-repeat: no-repeat;
			background-attachment: local;
			border-bottom: 1px solid #CCC;
			border-collapse: collapse;
			th {
				white-space: nowrap;
			}
			td {
				white-space: nowrap;
			}
		}
	}
}

.card .card-header {
  background-color: #B8E4FA;
  a{
    color: #fff;
  }
}

.page-contents{
	.tab{
		margin-bottom: 3em;
	}
	.nav-tabs{
		margin-bottom: 0px;
		.nav-item{
			padding: 0px;
			width: 25%;
			text-align: center;
			.nav-link{
				height: 100%;
				border-radius: 0px;
				border: none;
				color: #fff;
				background-color: #AFAFAF;
				margin-right: 2px;
				&.active{
					background-color: #B8E4FA;
				}
			}
		}
		// .nav-item.show .nav-link, .nav-tabs .nav-link.active
	}
	.tab-content{
		border: 1px solid #95989A;
	}
}

.box-category{
	border: 1px solid #B8E4FA;
	.box-category--heading{
		background-color: #B8E4FA;
		color: #fff;
		padding: 0.4em 0.8em;
	}
	.box-category--content{
		padding: 12px 15px;
		& > ul{
			padding-left: 25px;
		}
		& > *{
			&:last-child{
				margin-bottom: 0px;
			}
		}
	}
}



	

	//
	// Header
	//
	.header {

	}


	//
	// Footer
	//
	.footer {

	}
}
