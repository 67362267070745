//
// Header
//
body.home{
	.header{
		background-color: rgba(0, 0, 0, 0.6);
		position: absolute;
		width: 100%;
		z-index: 100;
	}

	// Layout
	.header {
		&-inner {
			display: block;
			max-width: 1200px;
			margin: 0 auto;
			@include media-breakpoint-up(md) {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				padding: 3px 0;
				nav {
					flex: 1 1 auto;
				}
			}
		}
		&-inner_page{
			display: none;
		}
	}

	// Logo
	.header-logo {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 100%;
		display: flex;
		align-items: center;
		img{
			@include media-breakpoint-down(sm){
				max-width: 80%;
				padding: 3px;
			}
		}
	}

	// Button
	.header-button-container {
		position: absolute;
		top: 0;
		right: 0;

		button {
			background-color: $primary;
			cursor: pointer;
		}
	}

	// Nav
	.header-nav {
		list-style: none;
		margin: 0;
		padding: 0;
		height: 100%;

		@include media-breakpoint-up(md) {
			display: flex;
			align-items: stretch;
			justify-content: flex-end;
			flex-wrap: wrap;
		}

		> li {
			a {
				display: flex;
				align-items: center;
				padding: .5em 1em;
				height: 100%;
				color: #fff;
			}
		}
		
	}

	.header-global-nav{
		background-color: rgba(355, 355, 355, 0.8);
		@include media-breakpoint-down(sm){
			display: none;
		}
		.header-global-nav_wrap{
			max-width: 1200px;
			margin: 0 auto;
			ul{
				display: flex;
				justify-content: space-between;
				list-style: none;
				text-align: center;
				padding: 0;
				margin: 0;
				li{
					padding: 10px 0;
					font-size: rem-calc(15);
					a{
						color: #4B4B4B;
						span{
							font-size: rem-calc(10);
						}
					}
				} 
			}
		}
	}
}

body.page{
	.header {
		background-color: rgb(0, 0, 0);
		width: 100%;
		z-index: 100;
	}

	// Layout
	.header {
		&-inner {
			display: none;
		}
		&-inner_page {
			display: block;
			max-width: 1200px;
			margin: 0 auto;
			@include media-breakpoint-up(md) {
				display: flex;
				justify-content: space-between;
				align-items: stretch;
				padding: 3px 0;
				nav {
					flex: 1 1 auto;
				}
			}
		}
	}

	// Logo
	.header-logo {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 100%;
		display: flex;
		align-items: center;
		@include media-breakpoint-down(sm){
			padding: 13px;
		}
	}

	// Button
	.header-button-container {
		position: absolute;
		top: 0;
		right: 0;

		button {
			background-color: $primary;
			cursor: pointer;
		}
	}

	// Nav
	.header-nav {
		list-style: none;
		margin: 0;
		padding: 0;
		height: 100%;

		@include media-breakpoint-up(md) {
			display: flex;
			align-items: stretch;
			justify-content: flex-end;
			flex-wrap: wrap;
		}

		> li {
			a {
				display: flex;
				align-items: center;
				padding: .5em 1em;
				height: 100%;
				color: #fff;
			}
		}
		
	}

	.header-global-nav{
		display: block;
		background-color: rgb(355, 355, 355);
		box-shadow: 0px 1px 4px 0px #ccc;
		@include media-breakpoint-down(sm){
			display: none;
		}
		.header-global-nav_wrap{
			max-width: 1200px;
			margin: 0 auto;
			ul{
				display: flex;
				justify-content: space-between;
				list-style: none;
				text-align: center;
				padding: 0;
				margin: 0;
				li{
					padding: 10px 0;
					font-size: rem-calc(15);
					a{
						color: #4B4B4B;
						span{
							display: none;
						}
					}
				} 
			}
		}
	}
	.accordion{
		.card{
			.card-header{
				background-color: #f0f0f0;
				padding: 10px;
				border-bottom: 2px solid #fff;
				.collapsed{
					&::before{
						transform: rotate(90deg);
					}
				}
				a{
					color: #4B4B4B;
					font-size: rem-calc(16);
					padding-left: 20px;
				}
			}
			.card-body{
				.list-content-icon{
					padding: 5px 25px;
					a{
						color: #4B4B4B;
					}
					&::before{
						left: 15px;
					}
				}
			}
		}
	}

	
}

