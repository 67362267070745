﻿//
// Common
//
.drawer_menu{
 display: none;
 @include media-breakpoint-down(sm){
    display: block;
  }
}
.drawer_menu * {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
  text-decoration: none;
  list-style: none;
}

.drawer_menu a {
  color: inherit;
  text-decoration: none;
}

.drawer_menu a:visited {
  color: inherit;
}

.drawer_menu .drawer_bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: rgba(51, 51, 51, 0.5);
  display: none;
  top: 0;
  left: 0;
}

.drawer_menu .drawer_button {
  display: block;
  background: none;
  border: none;
  padding: 0;
  width: 35px;
  letter-spacing: 0.1em;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1001;
  text-align: center;
  outline: none;
}

.drawer_menu .drawer_button.active .drawer_bar {
  width: 35px;
}

.drawer_menu .drawer_button.active .drawer_bar1 {
  transform: rotate(45deg);
}

.drawer_menu .drawer_button.active .drawer_bar2 {
  opacity: 0;
}

.drawer_menu .drawer_button.active .drawer_bar3 {
  transform: rotate(-45deg);
}

.drawer_menu .drawer_button.active .drawer_menu_text {
  display: none;
}

.drawer_menu .drawer_button.active .drawer_close {
  display: block;
}

.drawer_menu .drawer_bar {
  display: block;
  height: 2px;
  margin: 10px 0;
  transition: all 0.2s;
  transform-origin: 0 0;
}

.drawer_menu .drawer_text {
  text-align: center;
  font-size: 10px;
}

.drawer_menu .drawer_close {
  letter-spacing: 0.08em;
  display: none;
}

.drawer_menu .drawer_menu_text {
  display: block;
}

.drawer_menu .drawer_nav_wrapper {
  width: 312px;
  height: 100%;
  transition: all 0.2s;
  transform: translate(312px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #FFF;
}

.drawer_menu .drawer_nav_wrapper.open {
  transform: translate(0);
  background-color: #f0f0f0;
}

.drawer_menu.left .drawer_button {
  right: auto;
  left: 32px;
}

.drawer_menu.left .drawer_nav_wrapper {
  transform: translate(-312px);
  right: auto;
  left: 0;
}

.drawer_menu.left .drawer_nav_wrapper.open {
  transform: translate(0);
}

/*+++ Default Navigation CSS +++*/
.drawer_menu .drawer_nav {
  padding: 60px 10px 10px
}

.drawer_menu .drawer_nav li {
  font-size: 16px;
  margin-bottom: 15px;
}

/*+++ Default Button Color +++*/

.drawer_menu .drawer_button .drawer_bar {
  background-color: #fff;
}

.drawer_menu .drawer_button.active .drawer_bar {
  background-color: #000;
}